import 'jquery-ui-bundle/jquery-ui.css'
import 'jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.css'
import 'bootstrap-select/dist/css/bootstrap-select.css'
import 'nprogress/nprogress.css'
import 'font-awesome/css/font-awesome.css'
import 'vendor/react-select.scss'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import 'core-js'
import 'whatwg-fetch'
import 'script-loader!jquery'
import 'script-loader!jquery-ujs'
import 'script-loader!jquery-ui-bundle/jquery-ui'
import 'script-loader!jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon'
import 'script-loader!jquery-ui-timepicker-addon/dist/i18n/jquery-ui-timepicker-de'
import 'script-loader!vendor/datepicker-de'
import 'script-loader!bootstrap-sass/assets/javascripts/bootstrap'
import 'script-loader!bootstrap-select/dist/js/bootstrap-select'
import 'script-loader!nprogress'
import 'script-loader!vendor/plugins/download-file'
import 'script-loader!vendor/plugins/sweetalert2'
import 'script-loader!vendor/plugins/upload-file'
import 'script-loader!vendor/plugins/base'
import 'script-loader!services/toggle-checkbox'
import copy from 'copy-to-clipboard'
// this file should only include a shared code (node_modules or vendor folder)

window.$(() => {
  $('a[data-clipboard-copy]').on('click', e => {
    e.preventDefault();
    copy(e.target.dataset.clipboardCopy);
  });

  $('input[type="checkbox"]#select-all-checkbox').change(function() {
      $("label.data-grid-select > input[type=checkbox]").each((i, r) => {
          r.checked = this.checked;
      });
  });

  $('a.popover-button').popover({ placement: 'bottom', toggle: 'popover'}).on("click", function(){
      $('.popover').addClass('my-super-popover')
  });

  $("table#reports-table tr label.data-grid-select input[type=checkbox]").change(()=> {
      let isSelected = false;
      $("table#reports-table tr td label.data-grid-select input[type=checkbox]").each((index, elem) => {
          if ( elem.checked ) {
              isSelected = true;
          }
      });

      console.log('isSelected' + isSelected);

      if ( isSelected ){
          $('span#reports-export-button-text').text(window.gon.I18n.btns.export_selected);
          $('a#reports-delete-button').text(window.gon.I18n.btns.remove_selected);
      } else {
          $('span#reports-export-button-text').text(window.gon.I18n.btns.export_all);
          $('a#reports-delete-button').text(window.gon.I18n.btns.remove_all);
      }
  });
});

window.submitFormConfirmation = (formId, isChanged, messgeText, okButtonText, cancelButtonText) => {
    if (isChanged) {
        const swalWithBootstrapButtons = Swal.mixin({
            position: 'center',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-primary btn-lg action-btn',
                cancelButton: 'btn btn-default btn-lg action-btn',
                actions: "popup-actions-container",
                htmlContainer: "popup-html-container"
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            icon: 'warning',
            title: messgeText,
            showCancelButton: true,
            confirmButtonText: okButtonText || "Save",
            cancelButtonText: cancelButtonText || "Cancel"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                $("form#" + formId).submit();
            }
        });
    } else {
        $("form#" + formId).submit();
    }

};
